#appContainer {
    background-image: url("./images/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

a {
  text-decoration: none;
} 
@media (max-width: 800px) {
    #appContainer {
      background-image: url("./images/background_portrait.jpg");
    }  
}

#appHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}