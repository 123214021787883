.hikePreviewComponent {
    height: 215px;
    width: 220px;
    margin: 10px;
    background-color: rgba(255,255,255,0.6);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    overflow: hidden;
}

.hikePreviewComponent > p {
    color: #026475;
    font-size: 13px;
    margin: 15px 0px 10px 0px;
    font-weight: bold;
    align-self: center;
    text-align: center;
}

.hikesDescriptions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hikesDescriptions > p {
    color: black;
    font-size: 13px;
    margin: 0px 10px 10px 1px;
}

.hikeDefaultPreviewImage {
    width: 80%;
    align-self: center;
    margin-left: 40px;
}

.hikeFavoritePreviewImage {
    width: 80%;
    align-self: center;
}