 
.titleBar {
    margin-top: 10px;
    background-color: rgba(255,255,255,0.6);
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
}
  
.titleBar > h1 {
    font-size: 14px;
    padding-bottom: 2px;
    color: #026475;
    margin: 2px;
}