.regionsComponent {
    display: flex;
    justify-content: center;
    height: 100vh;
    overflow-y: auto;
}

.regionsContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}