@keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

.spinner:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 55%;
    left: 25%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border-radius: 50%;
    border: 5px solid rgba(180, 180, 180, 0.6);
    border-top-color: rgba(0, 0, 0, 0.6);
    animation: spinner 0.6s linear infinite;
  }

  @media (max-width: 765px) {
    .spinner:after {
        left: 50%;
    }
}
