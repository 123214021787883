.hikeComponent {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
	margin-top: 10px;  
	padding-bottom: 20px;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.mapContainer {
	width: 48%;
	min-width: 375px;
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-left: 1%;
}

.hikeDetailsContainer, .hikePicturesContainer {
	background-color: rgba(255,255,255,0.8);
	width: 48%;
	min-width: 375px;
	height: 100%;
	overflow-y: auto;
	margin-right: 1%;
}

#map {
	background-color: rgba(255,255,255,1);
	min-height: 0;
	flex: 1;
}

@media (max-width: 850px) {
    .mapContainer, .hikeDetailsContainer, .hikePicturesContainer {
		width: 95%;
		min-width: 0px;
		margin: 0px;
		height: calc(100% - 50px);
	}

	.tabsContainer {
		background-color: rgba(255,255,255,0.8);
		height: 25px;
	}
}

.form {
    padding-top: 5px;
    padding-bottom: 10px;
}

.formfield {
	color: #026475;
	text-align:left;
    font-size:0.8em;
    padding-bottom: 5px;	
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.formfield .label {    
	width: 125px;    
    padding-left: 10px;
    vertical-align: top;
	font-weight: bold;
}

.formfield .value{
	width: calc(100% - 150px);
    padding-left: 10px;
	white-space: pre-wrap;
}

.styledTabsContainer {
	display: flex;
	justify-content: center;
}