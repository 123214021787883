.breadcrumbsComponent {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 7px;
}

.breadcrumbsComponent .breadcrumbsItemComponent::after {
    content: "/";
}
    
.breadcrumbsComponent .breadcrumbsItemComponent:last-child::after {
    content: "";
}