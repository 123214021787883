.regionHikesComponent {
    display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
	margin-top: 10px;  
	padding-bottom: 20px;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.mapContainer {
	width: 48%;
	min-width: 375px;
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-left: 1%;
}

.hikeListContainer {
    background-color: rgba(255,255,255,0.8);
	width: 48%;
	min-width: 375px;
	height: 100%;
	overflow-y: auto;
	margin-right: 1%;
}

@media (max-width: 850px) {
    .mapContainer, .hikeListContainer {
		width: 95%;
		min-width: 0px;
		margin: 0px;
		height: calc(100% - 50px);
	}

	.tabsContainer {
		background-color: rgba(255,255,255,0.8);
		height: 25px;
	}
}