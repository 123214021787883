.hikesComponent {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow-y: auto;
}

.hikesContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
} 

.navigation {
    background-color: rgba(255,255,255,0.6);
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
	color: #026475;
    font-size: 14px;
	font-weight: bold;
    margin-bottom: 20px;
}

.menuLink {
	display: inline-block;
    cursor: pointer;
    padding-bottom: 2px;
    margin: 2px;
}

.menuConnector {
    padding-top: 1px;
    margin-left: 10px;
    margin-right: 10px;
}