.regionComponent {
    height: 215px;
    width: 220px;
    margin: 10px;
    background-color: rgba(255,255,255,0.6);
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.regionComponent > p {
    color: #026475;
    font-size: 15px;
    font-weight: bold;
    align-self: center;
}

.regionImage {
    width: 95%;
    align-self: center;
}