.hikesPreviewComponent {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.hikesPreviewContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}