.breadcrumbsItemComponent {
    display: flex;
    justify-content: center;
    color: #026475;
    font-size: 14px;
	font-weight: bold;
}

.breadcrumbsItemComponent a {
    margin: 0px 10px 0px 10px;
}

.breadcrumbsItemComponent > a:link, .breadcrumbsItemComponent > a:visited {
    color: #026475;
 }

.breadcrumbsItemComponent a:hover {
    text-decoration: underline;
}